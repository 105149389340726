import { isEmpty, escape } from 'lodash';
import { TRANSFORM_TYPE_FIT } from '@wix/communities-blog-universal/dist/src/constants/image';

import {
  getPostCover,
  getCanonicalPostUrl,
  POST_EXCERPT_MAX_LENGTH,
  getImageUrl,
} from '@wix/communities-blog-client-common';

import { getCategoryUrl } from './get-category-url';
import { getContentText } from './content-text';

const getDescription = post => {
  const text = post.excerpt || getContentText(post.content);
  return text.slice(0, POST_EXCERPT_MAX_LENGTH);
};

const getImageSchema = (image, imageHost) => ({
  '@type': 'ImageObject',
  height: image.height,
  width: image.width,
  url: getImageUrl({
    imageHost,
    image,
    maxWidth: 1000,
    maxHeight: 1000,
    quality: 80,
    type: TRANSFORM_TYPE_FIT,
    format: 'jpg',
  }),
});

const getAuthorSchema = ({ name, image }, imageHost) => {
  return {
    '@type': 'Person',
    name,
    ...(image
      ? {
          image: {
            '@type': 'ImageObject',
            url:
              typeof image === 'string'
                ? image
                : getImageUrl({
                    image,
                    imageHost,
                    maxWidth: 1000,
                    maxHeight: 1000,
                    quality: 80,
                    type: TRANSFORM_TYPE_FIT,
                    format: 'jpg',
                  }),
          },
        }
      : {}),
  };
};

export const getArticleSection = (post, categoryMap) =>
  post.categoryIds
    .map(id => categoryMap[id])
    .filter(Boolean)
    .map(({ label }) => label)
    .join(', ');

export const getPostSchema = ({ post, categoryMap, postPageSectionUrl, publisher, imageHost, videoHost }) => {
  if (!publisher || isEmpty(post) || !post.owner) {
    return undefined;
  }
  const { shouldRender, imageMetadata } = getPostCover(post, imageHost, videoHost);
  const postUrl = getCanonicalPostUrl({ post, postPageSectionUrl });
  const description = getDescription(post);
  const articleSection = getArticleSection(post, categoryMap);

  return {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    author: getAuthorSchema(post.owner, imageHost),
    articleSection,
    dateModified: post.lastPublishedDate,
    datePublished: post.firstPublishedDate,
    headline: escape(post.title),
    mainEntityOfPage: { '@id': postUrl, '@type': 'WebPage' },
    description: escape(description),
    url: postUrl,
    image: shouldRender && imageMetadata ? getImageSchema(imageMetadata, imageHost) : {},
    publisher,
  };
};

const getPostsSchema = (appConfig, posts, categoryMap) => {
  const { postPageSectionUrl, publisher } = appConfig;
  return posts.map(post =>
    getPostSchema({
      post,
      categoryMap,
      postPageSectionUrl,
      publisher,
      imageHost: appConfig.imageHost,
      videoHost: appConfig.videoHost,
    }),
  );
};

export const getCategorySchema = (appConfig, category, posts, categoryMap) => {
  const { sectionUrl, categoryPath } = appConfig;

  return {
    '@context': 'https://schema.org',
    '@type': 'CollectionPage',
    url: getCategoryUrl(sectionUrl, categoryPath, category.slug),
    headline: escape(category.label),
    description: escape(category.description),
    hasPart: getPostsSchema(appConfig, posts, categoryMap),
    sameAs: [],
  };
};

export const getWebsiteJSONSchema = sectionUrl => {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    '@id': '#website',
    url: sectionUrl,
  };
};
